import { Controller } from "@hotwired/stimulus"
import { createRoot } from 'react-dom/client';
import React from 'react';
import BooksApp from "../js/books/BooksApp";

export default class extends Controller {
  static values = {
    data: String
  }

  connect() {
      const books = JSON.parse(this.dataValue);

      const root = createRoot(document.getElementById('root'));
      root.render(<BooksApp booksData={books}/>);
  }
}