import { Controller } from '@hotwired/stimulus';
import Swal from 'sweetalert2';

export default class extends Controller {
    static targets = ['linkBtn'];

    // connect() {
    //     console.log("HELLO");
    // }

    linkClick(event) {
        event.preventDefault();

        // noinspection JSIgnoredPromiseFromCall
        Swal.fire({
            title: 'Download link',
            text: this.linkBtnTarget.getAttribute('href'),
        });
    }
}
