import React from "react";
import 'bootstrap';
import PropTypes from "prop-types";
import parse from "html-react-parser";
import {BooksTableUtils} from "./BooksTableUtils";
import {AgGridReact} from "ag-grid-react";
import BooksDetail from './BookDetail';
import BookModel from './BookModel';

export default class BooksTable extends React.Component {
    constructor(props) {
        super(props);
        this.utils = new BooksTableUtils();
        this.state = {
            detailShow: false,
            detailBook: null
        }

        this.pageSizeSelect = React.createRef();

        this.detailClosed = this.detailClosed.bind(this);
        this.thumbRenderer = this.thumbRenderer.bind(this);
        this.onThumbClick = this.onThumbClick.bind(this);

        this.defaultPageSize = 10;
    }

    getBooksObjects(booksData) {
        let books = [];

        booksData.forEach((datum) => {
            let book = new BookModel();
            book.id = datum.key;
            book.thumb = this.utils.bookImage(datum, 100, 100);
            book.cover = this.utils.bookImage(datum, 300, 300);
            book.title = datum.title;
            book.author = this.utils.authorLink(datum);
            book.narrator = this.utils.narratorLink(datum);
            book.duration = this.utils.asString(datum.duration);
            book.released = this.utils.asString(datum.release_date);
            book.description = this.utils.asString(datum.description, 800);

            book.abridged = this.utils.asBool(datum.abridged);
            book.language = datum.language;
            book.copyright = this.utils.asString(datum.copyright);
            book.rating_average = parseFloat(datum.rating_average);
            book.rating_count = parseInt(datum.rating_count);
            book.genre = datum.genre;
            book.files = this.utils.fileLinks(datum);
            book.summary = this.utils.asString(datum.summary);
            book.publisher = datum.publisher;
            book.asin = datum.asin;
            book.region = datum.region;
            book.ayce = this.utils.asBool(datum.ayce);

            book.series = this.utils.seriesLink(datum)
            if ('series_sequence' in datum) {
                book.series_sequence = datum.series_sequence;
            }

            if (book.released === '2200-01-01') {
                book.released = this.utils.asString(datum.purchase_date) + '*';
            }

            books.push(book);
        });

        return books;
    }

    cellRenderer(params) {
        if (params.value !== undefined) {
            return (parse(params.value));
        }
    }

    thumbRenderer(params) {
        // noinspection JSCheckFunctionSignatures
        return React.createElement("a", {onClick: this.onThumbClick, style: {cursor: 'pointer'}}, parse(params.value));
    }

    onGridReady = (params) => {
        this.gridApi = params.api;
    }

    onPageSizeChanged = () => {
        const sizeSelect = this.pageSizeSelect.current;
        const value = sizeSelect.options[sizeSelect.selectedIndex].value;
        this.gridApi.paginationSetPageSize(Number(value));
    };

    onThumbClick(params) {
        let t = params.target;
        let rowNode = this.gridApi.getRowNode(t.dataset.bookId);
        this.setState({detailShow: true, detailBook: rowNode.data});
    }

    detailClosed() {
        this.setState({detailShow: false});
    }

    getRowId = params => params.data.id;

    render() {
        const {booksData} = this.props;
        const {detailShow, detailBook} = this.state;

        const columns = [
            {
                field: 'id',
                hide: true
            },
            {
                field: 'thumb',
                autoHeight: true,
                flex: 1,
                cellRenderer: this.thumbRenderer,
            },
            {
                field: 'title',
                flex: 2,
                wrapText: true,
                sortable: true,
                filter: true,
            },
            {
                field: 'series',
                flex: 1,
                sortable: true,
                filter: true,
                cellRenderer: this.cellRenderer
            },
            {
                field: 'genre',
                flex: 1,
                sortable: true,
                filter: true,
                cellRenderer: this.cellRenderer
            },
            {
                field: 'author',
                flex: 1,
                sortable: true,
                filter: true,
                cellRenderer: this.cellRenderer
            },
            {
                field: 'narrator',
                flex: 1,
                sortable: true,
                filter: true,
                cellRenderer: this.cellRenderer
            },
            {
                field: 'duration',
                flex: 1,
                sortable: true,
                cellRenderer: this.cellRenderer
            },
            {
                field: 'released',
                flex: 1,
                sortable: true,
                cellRenderer: this.cellRenderer
            },
            {
                field: 'description',
                flex: 4,
                wrapText: true,
                filter: true,
                cellRenderer: this.cellRenderer,
            },
        ];

        const data = this.getBooksObjects(booksData);

        const options = {
            rowData: data,
            columnDefs: columns,
            pagination: true,
            paginationPageSize: this.defaultPageSize,
            onGridReady: this.onGridReady,
            getRowId: this.getRowId,
            rowSelection: 'single',
        };

        return (
            <>
                <BooksDetail
                    show={detailShow}
                    onDetailClose={this.detailClosed}
                    book={detailBook}
                />
                <div className="row">
                    <div style={{width: '98%', marginLeft: 'auto', marginRight: 'auto'}}>
                        <div className="col-sm-2">
                            <div className="input-group mb-3">
                                <div className="input-group-prepend">
                                    <label className="input-group-text" htmlFor="inputGroupSelect01">Page Size</label>
                                </div>
                                <select className="custom-select" ref={this.pageSizeSelect} onChange={() => this.onPageSizeChanged()} value={this.defaultPageSize}>
                                    <option value="10">10</option>
                                    <option value="50">50</option>
                                    <option value="100">100</option>
                                </select>
                            </div>
                        </div>
                        <div className="col-sm-1">
                            <div className="input-group mb-3">
                                <button type="button" className="btn btn-secondary" onClick={() => this.gridApi.setFilterModel(null)}>Clear Filters</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-12">
                        <div style={{width: '98%', height: 600, marginLeft: 'auto', marginRight: 'auto'}} className="ag-theme-alpine">
                            <AgGridReact
                                gridOptions={options}
                            />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-12">
                        <span>Notes:</span>
                        <p>Released date with asterisk is the purchase date</p>
                    </div>
                </div>
            </>

        )
    }
}

BooksTable.propTypes = {
    booksData: PropTypes.arrayOf(PropTypes.object).isRequired,
}