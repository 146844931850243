import React from "react";
import 'bootstrap';
import parse from "html-react-parser";
import PropTypes from "prop-types";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import {BooksTableUtils} from "./BooksTableUtils";
import BookModel from "./BookModel";

export default class BooksDetail extends React.Component {
    constructor(props) {
        super(props);
        this.utils = new BooksTableUtils();
        this.book = new BookModel();
    }

    render() {
        const {show, onDetailClose} = this.props;
        if (this.props.book) {
            this.book = this.props.book;
        }
        const book = this.book;

        return (
            <>
                <Modal
                    show={show}
                    onHide={onDetailClose}
                    dialogClassName="modal-lg"
                >
                    <Modal.Header closeButton>
                        <Modal.Title>{book.title}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="row">
                            <div className="col-md-5" id="detail_image">{parse(book.cover)}</div>
                            <div className="col-md-7" id="detail_details">
                                <table className="table">
                                    <tbody>
                                    <tr>
                                        <th className="detail_key">Author:</th>
                                        <td className="detail_value" id="author">{parse(book.author)}</td>
                                    </tr>
                                    <tr>
                                        <th className="detail_key">Narrated By:</th>
                                        <td className="detail_value" id="narrated_by">{parse(book.narrator)}</td>
                                    </tr>
                                    <tr>
                                        <th className="detail_key">Duration:</th>
                                        <td className="detail_value" id="duration">{book.duration}</td>
                                    </tr>
                                     <tr>
                                        <th className="detail_key">Genre:</th>
                                        <td className="detail_value" id="duration">{book.genre}</td>
                                    </tr>
                                    <tr>
                                        <th className="detail_key">Series:</th>
                                        <td className="detail_value" id="series">{(book.series_sequence > 0) ? parse(book.series) : ''}</td>
                                    </tr>
                                    <tr>
                                        <th className="detail_key">Rating:</th>
                                        <td className="detail_value" id="rating">{book.rating_average.toPrecision(2)} ({book.rating_count})</td>
                                    </tr>
                                    <tr id="mp3_details">
                                        <th className="detail_key">Files:</th>
                                        <td className="detail_value" id="files">{book.files.map(function (item) {
                                            // noinspection JSCheckFunctionSignatures
                                            return React.cloneElement(parse(item.data), {key: item.id});
                                        })}</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <div id="summary" className="summary">{parse(book.summary)}</div>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={onDetailClose}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
            </>
        );
    }
}

BooksDetail.propTypes = {
    show: PropTypes.bool.isRequired,
    onDetailClose: PropTypes.func.isRequired,
    book: PropTypes.instanceOf(BookModel),
}