import {Controller} from '@hotwired/stimulus';
import axios from 'axios';
import {Toasts} from '../components/toasts';
import $ from 'jquery';
import tinymce from 'tinymce';
import 'tinymce/plugins/table';

// noinspection JSUnusedGlobalSymbols,JSUnresolvedReference
export default class extends Controller {
    static targets = ['uploadIntelBtn', 'formInfo', 'formProblem', 'formResolution'];

    saveAndAddDone = false;
    saveAndReturnDone = false;

    connect() {
        if (this.hasFormInfoTarget) {
            this._initMce(this.formInfoTarget);
        }

        if (this.hasFormProblemTarget) {
            this._initMce(this.formProblemTarget);
        }

        if (this.hasFormResolutionTarget) {
            this._initMce(this.formResolutionTarget);
        }
    }

    uploadIntelClick() {
         new Toasts().toast("Database Upload", "Intel database is being uploaded. Please wait...", 'success');
        axios({
            method: 'post',
            url: '/api/vodka/intel/upload',
            data: {
                xyz: 'yes',
                code: 222
            },
            validateStatus: status => status === 201
        }).then(function (data) {
            if (data.data.status !== 222) {
                new Toasts().toast(data.data.title, data.data.detail, 'error');
                return;
            }
            new Toasts().toast(data.data.title, data.data.detail, 'success');
        }).catch(function (reason) {
            // console.log(reason.response);
            if (reason.response.status === 200) {
                new Toasts().toast("Error", "Must be fully logged in!", 'error');
                return;
            }
            new Toasts().toast("Error", "Intel upload failed!", 'error');
        });
    }

    _initMce(target) {
        tinymce.init({
            target: target,
            base_url: '/build/tinymce',
            promotion: false,
            branding: false,
            plugins: 'table',
            menubar: 'edit insert view format table tools help',
            entity_encoding: "raw",
            setup: (editor) => {
                editor.on('change', () => editor.save());
            }
        }).then((e) => {
            e[0].setContent(`<p>${$(target).text()}</p>`);
        });
    }

}
