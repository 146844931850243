import Swal from 'sweetalert2';

export class Toasts {
	constructor() {
		this.swalToast = Swal.mixin({
			toast: true,
			position: 'top-end',
			timer: 5000,
			timerProgressBar: true,
			showConfirmButton: false,
		})
	}

	toast(title, msg, icon) {
		this.swalToast.fire({
			title: title,
			text: msg,
			icon: icon,
		})
	}
}


