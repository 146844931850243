export default class BookModel {
    constructor() {
    }

    id = '';
    title = '';
    thumb = '';
    cover = '';
    author = '';
    narrator = '';
    duration = '';
    released = '';
    description = '';

    abridged = false;
    language = '';
    copyright = '';
    rating_average = 0.0;
    rating_count = 0;
    genre = '';
    files = [];
    summary = '';
    publisher = '';
    asin = '';
    region = '';
    ayce = false;
    series = '';
    series_sequence = 0;
}