import { Controller } from '@hotwired/stimulus';
import axios from 'axios';
import { Toasts } from '../components/toasts';
import $ from 'jquery';

// noinspection JSUnusedGlobalSymbols,JSUnresolvedReference
export default class extends Controller {
    static targets = ['uploadDataBtn', 'importJsonBtn', 'resourceTypesJs'];
    types = {}

    connect() {
        this.types = JSON.parse(this.resourceTypesJsTarget.value);
    }

    uploadDataClick(){
        new Toasts().toast("Database Upload", "Data database is being uploaded. Please wait...", 'success');
        axios({
            method: 'post',
            url: '/api/vodka/data/upload',
            data: {
                xyz: 'yes',
                code: 222
            },
            validateStatus: status => status === 201
        }).then(function (data) {
            if (data.data.status !== 222) {
                new Toasts().toast(data.data.title, data.data.detail, 'error');
                return;
            }
            new Toasts().toast(data.data.title, data.data.detail, 'success');
        }).catch(function (reason) {
            if (reason.response.status === 200) {
                new Toasts().toast("Error", "Must be fully logged in!", 'error');
                return;
            }
            new Toasts().toast("Error", "Data upload failed!", 'error');
        });
    }

    updateSelect(elClass, val, txt){
        $(elClass + " select").val(val);

        if($(elClass + " .ts-control .item").length === 0){
            // console.log("Appended " + elClass);
            $(elClass + " .ts-control").append('<div data-value="' + val + '" className="item" data-ts-item="">' + txt+ '</div>');
            $(elClass + " .ts-control .items-placeholder").remove();
        }else{
            // noinspection JSJQueryEfficiency
            $(elClass + " .ts-control .item").attr('data-value', val);
            // noinspection JSJQueryEfficiency
            $(elClass + " .ts-control .item").text(txt);
        }
    }

    importJsonClick(event){
        event.preventDefault();
        // console.log(event);
        let json = JSON.parse($(".jsonString textarea").val());
        // console.log(json);
        $(".uuidEl input").val(json['uuid']);

        this.updateSelect(".modEl", json['mod'], json['mod']);

        this.updateSelect(".mfgEl", json['mfg'], json['mfg']);

        $(".nameEl input").val(json['name']);
        $(".descEl input").val(json['description']);
        $(".s3pathEl input").val(json['s3Path']);

        this.updateSelect(".typeEl", json['type'], this.types[json['type']]);

        $(".linkEl input").val(json['linkPath']);
        $(".zipEl input").val(json['zipPath']);
        $(".zipLinkEl input").val(json['zipIntPath']);
        $(".versionEl input").val(json['version']);
        $(".sizeEl input").val(json['size']);




    }
}
