import React from "react";
import 'bootstrap';
import BooksTable from "./BooksTable";

export default class BooksApp extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <BooksTable
                { ...this.props}
            />
        )
    }
}