export class BooksTableUtils {
    asString(str, len) {
        if (!str) {
            return "";
        }
        if (len) {
            str = str.substring(0, len);
        }
        // noinspection RegExpUnnecessaryNonCapturingGroup
        return str.replace(/(?:\r\n|\r|\n)/g, ' ').replace('  ', ' ');
    }

    asBool(str) {
        if (typeof str === 'boolean') {
            return str;
        }

        if (str === undefined) {
            return false;
        }

        return str.lower === 'true';
    }

    fixedEncodeURIComponent(str) {
        return encodeURIComponent(str).replace(/[!'()*]/g, escape);
    }

    bookImage(book, width = 200, height = 200) {
        return (book && book.image && book.image.length > 0)
            ? ("<img src=\"/books/web/thumb/" + this.fixedEncodeURIComponent(book.image).replace(" ", "+") + "\" width=\"" + width + "\" height=\"" + height + "\" data-book-id=\"" + book.key + "\">")
            : "<img src=\"/books/web/assets/no-cover.png\" width='" + width + "' height='" + height + "'>";
    }

    authorLink(book) {
        if (!book || !book.author) {
            return "";
        }
        if (book.author_link) {
            return "<a href=\"" + book.author_link + "\">" + this.asString(book.author, 50)
                + "</a> ";
        }
        return this.asString(book.author, 50);
    }

    publisherLink(book) {
        return this.asString(book.publisher);
    }

    narratorLink(book) {
        if (!book || !book.narrator_link) {
            return this.asString(book.narrated_by, 50);
        }
        return "<a href=\"" + book.narrator_link + "\">"
            + this.asString(book.narrated_by, 50) + "</a> ";
    }

    seriesLink(book) {
        if (!book) return "";
        let series_link = book.series_link;
        if (series_link) {
            if (series_link.indexOf("/")===0) {
                series_link = "https://audible.com" + series_link;
            }
            if (series_link.indexOf("http")===0) {
                return "<a href=\"" + series_link + "\">" + this.asString(book.series_name) + "</a> ";

            }
        }

        return this.asString(book.series_name);
    }

    fileLink(link, kind) {
        if (!link) {
            return "";
        }
        return "<a href=\"" + kind + "/" + this.fixedEncodeURIComponent(link) + "\" download>" + kind + "</a> ";
    }

    fileLinks(book) {
        let files = [];

        book.files.forEach((kind) => {
            const file = "/books/download?ext=" + kind + "&file=" + this.fixedEncodeURIComponent(book[kind]) + "&key=" + book.key + "&title=" + this.fixedEncodeURIComponent(book.title);
            const img = "<img src=\"/build/images/audio-wire/" + kind + ".png\" width=\"48\" height=\"48\">";
            const link = "<a href=\"" + file + "\">" + img + "</a>";
            files.push({id: book[kind], data: link});
        })

        return files;
    }
}